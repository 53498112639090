import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';

import { ControlDef } from './control.def';
import { ResourceService } from '../resource/resource.service';
import { Page } from '../';
import { Param } from '../resource/param';
import { SelectParam } from './select-param';
import { debounceTime, map, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { Subject, Observable, merge, of, Subscription } from 'rxjs';

@Component({
    selector: 'ls-select',
    templateUrl: './select.component.html'
})
export class LsSelectComponent implements OnInit, OnDestroy {
    @Input() formControl: FormControl;
    @Input() form: FormGroup;
    @Input() controlDef: ControlDef;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
            .pipe(
                switchMap(term => {
                    let model: any;

                    if (this.form.parent) {
                        model = this.form.parent.parent.value;
                    } else {
                        model = this.form.value;
                    }

                    let params = new Array<Param>();

                    params.push({
                        key: 'term',
                        value: term
                    });

                    if (this.controlDef.select.params) {
                        this.controlDef.select.params.forEach(param => {
                            let val = this.byString(model, param.value);

                            params.push({
                                key: param.key,
                                value: val
                            })
                        })
                    }

                    return this.service
                        .getPage(this.controlDef.select.resource, 1, params)
                        .pipe(
                            catchError(() => {
                                return of([]);
                            }),
                            map((page: Page<any>) => {
                                return page.items;
                            })
                        );
                })
            );
    }

    constructor(private service: ResourceService) {
    }

    sub$: Subscription;

    ngOnInit(): void {
        this.sub$ = this.form.controls[this.controlDef.id].valueChanges
            .pipe(
                tap(x => {
                    if (x === '') {
                        this.form.controls[this.controlDef.id].setValue(null, { emitEvent: false });
                    }
                })
            ).subscribe();

        if (!this.controlDef.select) {
            return;
        }

        if (this.controlDef.readonly) {
            return;
        }
    }

    ngOnDestroy() {
        this.sub$.unsubscribe();
    }


    private byString = function (o: any, s: string) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];

            if (o === null)
                return;

            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }

        return o;
    }

    get isValid(): boolean {
        return this.form.controls[this.controlDef.id].valid;
    }

    inputFormatter: (value: any) => string = (value: any) => {
        if (!this.controlDef || !this.controlDef.select || !this.controlDef.select.inputFormat) {
            return;
        }

        var formatted = this.formatter(value, this.controlDef.select.inputFormat)

        return formatted;
    };

    resultFormatter: (value: any) => string = (value: any) => {
        if (!this.controlDef || !this.controlDef.select || !this.controlDef.select.resultFormat) {
            return;
        }

        return this.formatter(value, this.controlDef.select.resultFormat)
    };

    formatter(value: any, format: string[]) {
        let formatted = format.reduce((accumulator: string, currentValue: string, currentIndex: number) => {
            if (currentIndex != 0) {
                accumulator += ' ';
            }
            return accumulator += value[currentValue];
        }, '');

        return formatted;
    };
}