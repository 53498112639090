import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { LsError } from '../../shared';
import { UpdateDB } from '../update-db/update-db';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UpdateDBService {
    private loginUrl = '/api/db';

    constructor(private http: HttpClient) { }

    createUpdate(updateDB: UpdateDB): Observable<UpdateDB> {
        let url = `/api/${updateDB.dbName}/db`;
        let body = JSON.stringify(updateDB);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http
            .post<UpdateDB>(url, body, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(response: HttpErrorResponse) {
        let error = new LsError(response);
        return throwError(error);
    }
}
