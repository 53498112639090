import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TabViewDef } from './tab-view.def';

@Component({
    selector: 'ls-tab-view',
    templateUrl: './tab-view.component.html',
})
export class LsTabViewComponent implements OnInit {
    tabViewDef: TabViewDef;
    id: number;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.params.subscribe(params =>
            this.id = +params['id']
        );

        this.route.data
            .subscribe((data: { tabViewDef: TabViewDef }) => {
                this.tabViewDef = data.tabViewDef;
            });
    }
}