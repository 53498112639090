import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RegisterService } from './register.service';
import { LsError } from '../../shared';
import { Register } from './register';

@Component({
    styleUrls: ['./register.component.css'],
    templateUrl: './register.component.html'
})
export class RegisterComponent {
    isLoading: boolean = false;
    isLoaded: boolean = false;
    message: string;
    loginToken: Register = new Register();
    error: LsError;

    constructor(private service: RegisterService, public router: Router) {
    }

    login() {
        this.isLoading = true;

        this.service.register(this.loginToken).subscribe(() => {
            this.router.navigate(['./login']);
        }, (error: LsError) => {
            this.error = error;
            this.isLoading = false;
        });
    }
}
