import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { ColumnDef } from './column.def';
import { ControlDef } from './control.def';
import { FormDef } from './form.def';
import { GroupDef } from './group.def';


@Component({
    selector: 'ls-form',
    templateUrl: './form.component.html',
})
export class LsFormComponent implements OnInit {
    @Input() formDef: FormDef;
    @Input() item: any;
    @Output() canceled = new EventEmitter();
    @Output() deleted = new EventEmitter();
    @Output() downloaded = new EventEmitter();
    @Output() saved = new EventEmitter();
    @Output() submitted = new EventEmitter();
    @Input() isDownloading: boolean;
    @Input() isDeleting: boolean;
    @Input() isSaving: boolean;
    @Input() isSubmitting: boolean;

    formGroup: FormGroup;
    payload: string;

    constructor(private _fb: FormBuilder) { }

    isSaved(): boolean {
        if (this.item.id && this.item.id != 0)
            return true;

        return false;
    }

    onAddNew(tableName: string) {
        let group = this.formDef.groups.find(o => {
            return o.id === tableName;
        })

        let columns = group.columns;

        let groupControls = this.createGroupFormControls(columns);

        let formGroup = new FormGroup(groupControls);

        const formArray = <FormArray>this.formGroup.controls[tableName];

        formArray.push(formGroup);
    }

    onRemove(tableName: string, model: any) {
        const formArray = <FormArray>this.formGroup.controls[tableName];

        for (var i = model[tableName].length; i-- > 0;) {
            let o = model[tableName][i];

            if (o.isSelected) {
                formArray.removeAt(i);
            }
        };
    }

    onCancel(model: any) {
        this.canceled.emit(model);
    }

    onDelete(model: any) {
        this.deleted.emit(model);
    }

    onDownload(model: any) {
        this.downloaded.emit(model);
    }

    onSave(model: any) {
        this.saved.emit(model);
    }

    onSubmit(model: any) {
        this.submitted.emit(model);
    }

    update(model: any) {
        if (this.formGroup) {
            this.formGroup.patchValue(model, { onlySelf: true });
        }
    }

    ngOnInit() {
        this.formGroup = this.createFormGroup(this.formDef);
        this.formGroup.patchValue(this.item, { onlySelf: true });
    }

    private createFormGroup(formDef: FormDef): FormGroup {
        const formControls = this.generateFormControls(formDef);

        return this._fb.group(formControls);
    }

    private generateFormControls(formDef: FormDef) {
        let controls = {};

        if (!(formDef)) {
            return controls;
        }

        this.generateGroupControls(controls, formDef.groups);

        return controls;
    }

    private generateGroupControls(controls: any, groups: GroupDef[] = []) {
        groups.forEach(group => {
            if (group.continuous) {
                let lineFormGroups = this._fb.array([]); //new Array<AbstractControl>();

                this.item[group.id].forEach((o: any) => {
                    //this.AddLine();
                    let groupControls = this.createGroupFormControls(group.columns);

                    let lineFormGroup = this._fb.group(groupControls);

                    lineFormGroups.push(lineFormGroup);
                });

                controls[group.id] = lineFormGroups;
            } else {
                let groupControls = this.createGroupFormControls(group.columns);

                this.merge(controls, groupControls);
            }
        });
    }

    private createGroupFormControls(columns: ColumnDef[] = []): any {
        let controls = {};

        columns.forEach(column => {
            let columnControls = this.createColumnFormControls(column.controls);
            this.merge(controls, columnControls);
        });

        return controls;
    }

    private createColumnFormControls(controlsArr: ControlDef[] = []): any {
        let controls = {};

        controlsArr.forEach(controlDef => {
            controls[controlDef.id] = this.createFormControl(controlDef);
        });

        return controls;
    }

    private createFormControl(control: ControlDef): FormControl {
        if (control.required) {
            return this._fb.control(control.value, Validators.required);
        } else {
            let ctrl = this._fb.control(control.value);

            ctrl.valueChanges.pipe(
                tap(x => {
                    console.log('tap');
                    console.log(x);
                    if (x === '') {
                        console.log('setting null');
                        ctrl.setValue(null, { emitEvent: false });
                    }
                }));

            return ctrl;
        }
    }

    private merge(obj1: any, obj2: any) {
        for (var attrname in obj2) { obj1[attrname] = obj2[attrname]; }
    }
}