import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    x: number = 23.5;

    private toNumber(str: string): number {
        str = str.replace(/\s+/g, '');

        var separator = ",";
        var separator2 = ".";

        var sepInd = str.indexOf(separator);
        var thSepInd = str.indexOf(separator2);

        if (sepInd > -1 && thSepInd > -1) {
            if (sepInd > thSepInd) {
                str = str.replace(separator2, '');
                str = str.replace(separator, '.');
            } else {
                str = str.replace(separator, '');
                str = str.replace(separator2, '.');
            }
        } else {
            str = str.replace(separator, '.');
        }

        var decimal = parseFloat(str);

        if (isNaN(decimal))
            return 0;
        else
            return decimal;
    }
}
