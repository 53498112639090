import { ColumnConfig } from './column-config';
import { ColumnDef } from './column.def';

export class Column {
    header: string;
    key: string;
    order: number;
    type: string;
    id: string;

    constructor(def: ColumnDef, config: ColumnConfig = null) {
        this.applyDef(def);
        this.applyConfig(config);
    }

    private applyDef(def: ColumnDef) {
        this.header = def.header;
        this.id = def.id;
        this.type = def.type || 'text';
    }

    private applyConfig(config: ColumnConfig) {
        if (config) {
            this.key = config.key;
            this.order = config.order;
        }
    }
}