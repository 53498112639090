import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ResourceService } from './resource.service';
import { Page } from '../';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ResolvePage<T> implements Resolve<Page<T>> {

    constructor(private service: ResourceService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Page<T>> {
        let resource = route.url[0].path;

        let parentUrl = route.parent.url[0];

        if (parentUrl) {
            let parentResource = route.parent.url[0].path;

            let parentID = route.parent.params['id'];

            resource = `${parentResource}/${parentID}/${resource}`;
        }

        let pageNo = route.queryParams['p'];

        if (!pageNo) {
            pageNo = 1;
        }

        return this.service
            .getPage<T>(resource, pageNo)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any) {
        return throwError(() => error);
    }
}