import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormDef } from '../form/form.def';
import { LsError } from '../error/error';
import { ResourceService } from '../resource/resource.service';
import { LsFormComponent } from '../form/form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteDialog } from '../dialogs/delete-dialog.module';

@Component({
    selector: 'ls-edit-form',
    templateUrl: './edit-form.component.html'
})
export class LsEditFormComponent implements OnInit {
    @ViewChild(LsFormComponent, { static: false })
    private formComponent: LsFormComponent;

    error: LsError;
    @Input() formDef: FormDef;
    item: any;
    isDeleting: boolean;
    isDownloading: boolean;
    isSaving: boolean;
    isSubmitting: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private service: ResourceService,
        private _modalService: NgbModal) { }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { item: any, formDef: FormDef }) => {
                this.item = data.item;
                this.formDef = data.formDef;

                if (this.formComponent)
                    this.formComponent.update(this.item);
            });
    }

    cancel(model: any): void {
        if (this.route.snapshot.url.length === 0) {
            //Forma tabe
            this.router.navigate(['../../../'], { relativeTo: this.route });
        } else {
            //Forma iš sąrašo (kuris tiek tabe, tiek ne gali būti)
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    delete(model: any): void {
        this.error = null;

        this._modalService
            .open(DeleteDialog)
            .result
            .then((result) => {
                this.isDeleting = true;
                let resource = this.getResource();
                this.service
                    .delete(resource, model.id)
                    .subscribe((o: any) => {
                        this.cancel(model);
                    }, (error: LsError) => {
                        this.error = error;
                        this.isDeleting = false;
                    });

            }, () => {
            });
    }

    download(model: any): void {
        this.error = null;
        this.isDownloading = true;

        let resource = this.getResource();

        this.service.save(resource, model).subscribe((o: any) => {
            if (this.item.id === 0) {
                this.router.navigate(['../' + o.id], { relativeTo: this.route });

                this.openLink(o.link);

                this.isDownloading = false;
            } else {
                this.service.get(this.getResource(), o.id).subscribe(data => {
                    this.item = data;

                    if (this.formComponent)
                        this.formComponent.update(data);

                    this.isDownloading = false;

                    this.openLink(o.link);
                });
            }
        }, (error: LsError) => {
            this.error = error;
            this.isDownloading = false;
        });
    }

    private openLink(url: any) {
        window.location.href = url;
    }

    save(model: any): void {
        this.error = null;
        this.isSaving = true;

        let resource = this.getResource();

        this.service.save(resource, model).subscribe((o: any) => {
            if (this.item.id === 0) {
                if (this.route.snapshot.url.length === 0) {
                    this.router.navigate(['../../../' + o.id], { relativeTo: this.route });
                } else {
                    this.router.navigate(['../' + o.id], { relativeTo: this.route });
                }

                this.isSaving = false;
            } else {
                this.service.get(resource, o.id).subscribe(data => {
                    this.item = data;

                    if (this.formComponent)
                        this.formComponent.update(data);

                    this.isSaving = false;
                });
            }
        }, (error: LsError) => {
            this.error = error;
            this.isSaving = false;
        });
    }

    submit(model: any): void {
        this.error = null;
        this.isSubmitting = true;
        let resource = this.getResource();

        this.service.save(resource, model).subscribe(() => {
            this.isSubmitting = false;

            if (this.route.snapshot.url.length === 0) {
                this.router.navigate(['../../../'], { relativeTo: this.route });
            } else {
                this.router.navigate(['../'], { relativeTo: this.route });
            }
        }, (error: LsError) => {
            this.error = error;
            this.isSubmitting = false;
        });
    }

    private getResource(): string {
        let resource: string;

        if (this.route.snapshot.url.length === 0) {
            resource = this.route.snapshot.parent.url[0].path;
        } else {
            resource = this.route.snapshot.url[0].path;

            if (this.route.snapshot.parent.url.length !== 0) {
                let parentResource = this.route.snapshot.parent.url[0].path;
                let parentID = this.route.snapshot.parent.params['id'];
                resource = `${parentResource}/${parentID}/${resource}`;
            }
        }

        return resource;
    }
}