import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LsAutofocusModule } from './autofocus/autofocus.module';
import { LsDeleteDialogModule } from './dialogs/delete-dialog.module';
import { LsEditFormModule } from './edit-form/edit-form.module';
import { LsErrorModule } from './error/error.module';
import { LsFilterModule } from './filter/filter.module';
import { LsFormModule } from './form/form.module';
import { LsListModule } from './list/list.module';
import { LsReportFormModule } from './report-form/report-form.module';
import { LsTabViewModule } from './tab-view/tab-view.module';
import { LsTableModule } from './table/table.module';


export { Autofocus, LsAutofocusModule } from './autofocus/autofocus.module';
export { DatabaseService } from './database/database.service';
export { DeleteDialog, LsDeleteDialogModule } from './dialogs/delete-dialog.module';
export { LsDynamicFormComponent, LsEditFormModule } from './edit-form/edit-form.module';
export { ErrorComponent, LsError, LsErrorModule } from './error/error.module';
export { Filter } from './filter';
export { LsFilterComponent } from './filter/filter.module';
export { CurrentDate, CurrentDateService } from './form/date-picker/current-date';
export { LsDateParserFormatter } from './form/date-picker/ls-date-parser-formatter';
export { LsDatepickerI18n } from './form/date-picker/ls-datepicker-i18n';
export { FormDef, LsFormModule } from './form/form.module';
export { I18n } from './i18n/i18n';
export { LsDynamicListComponent } from './list/list.module';
export { Page } from './page';
export { LsReportFormComponent } from './report-form/report-form.module';
export { ResolveFilter } from './resource/resolve-filter';
export { ResolveForm } from './resource/resolve-form';
export { ResolvePage } from './resource/resolve-page';
export { ResolveSingle } from './resource/resolve-single';
export { ResourceService } from './resource/resource.service';
export { LsRoute } from './route/route';
export { LsTabViewRoute } from './route/tab-view-route';
export { LsTabViewComponent, LsTabViewModule, ResolveTabView } from './tab-view/tab-view.module';
export * from './table/table.module';

const LS_MODULES = [
    FormsModule,
    ReactiveFormsModule,
    LsAutofocusModule,
    LsDeleteDialogModule,
    LsEditFormModule,
    LsErrorModule,
    LsFilterModule,
    LsFormModule,
    LsListModule,
    LsReportFormModule,
    LsTableModule,
    LsTabViewModule,
    NgbModule
];

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        LsAutofocusModule.forRoot(),
        LsDeleteDialogModule.forRoot(),
        LsEditFormModule.forRoot(),
        LsErrorModule.forRoot(),
        LsFilterModule.forRoot(),
        LsFormModule.forRoot(),
        LsListModule.forRoot(),
        LsReportFormModule.forRoot(),
        LsTabViewModule.forRoot()
    ],
    exports: LS_MODULES
})
export class SharedRootModule {
}

@NgModule({ imports: LS_MODULES, exports: LS_MODULES })
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedRootModule> { return { ngModule: SharedRootModule }; }
}