import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';;

import { DatabaseService } from '../database/database.service';
import { LsError } from '../error/error';
import { FormDef } from '../form/form.def';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
    selector: 'file-upload',
    template: '<input type="file" [multiple]="multiple" #fileInput><ls-error [error]="error"></ls-error><div>{{message}}</div>'
})
export class LsFileUploadComponent {
    error: LsError;
    message: string;
    id: number;
    item: any;
    @Input() multiple: boolean = true;
    @Input() formDef: FormDef;
    @ViewChild('fileInput', { static: false }) inputEl: ElementRef;

    constructor(private http: HttpClient, private dbService: DatabaseService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { item: any, formDef: FormDef }) => {
                this.item = data.item;
            });
    }

    upload() {
        this.error = null;
        this.message = null;
        let inputEl: HTMLInputElement = this.inputEl.nativeElement;
        let fileCount: number = inputEl.files.length;
        let formData = new FormData();
        if (fileCount > 0) { // a file was selected
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', inputEl.files.item(i));
            }

            let resource = this.route.snapshot.url[0].path;

            resource = resource.replace(new RegExp('-', 'g'), '');

            let url = `api/${this.dbService.db}/${resource.replace('-', '')}upload`;

            this.id = this.route.snapshot.params['templateID'];

            let taskID = this.item['taskID'];
            let templateID = this.item['templateID'];

            //if (this.item.id > 0) {
            //    url = url + `/${this.item.id}`;
            //};

            if (taskID > 0) {
                url = url + `/${taskID}`;
            };

            if (templateID > 0) {
                url = url + `/${templateID}`;
            };

            this.http
                .post(url, formData)
                .pipe(
                    catchError(this.handleError)
                )
                .subscribe(o => {
                    this.message = 'Įkelta';
                }, o => {
                    this.error = o;
                });
            // do whatever you do...
            // subscribe to observable to listen for response
        }
    }

    private handleError(response: HttpErrorResponse) {
        let error = new LsError(response);

        return throwError(error);
    }
}