import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LsError } from './error';
import { ErrorComponent } from './error.component';

export { ErrorComponent } from './error.component';
export { LsError } from './error';

@NgModule({
    declarations: [ErrorComponent],
    exports: [ErrorComponent],
    imports: [CommonModule],
    entryComponents: [ErrorComponent]
})
export class LsErrorModule {
    static forRoot(): ModuleWithProviders<LsErrorModule> { return { ngModule: LsErrorModule, providers: [] }; }
}