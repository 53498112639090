import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LsTableComponent } from './table.component';
import { ColumnDef } from './column.def';
import { ResolveTable } from '../resource/resolve-table';
import { TableConfigService } from './table-config.service';
import { TableDef } from './table.def';
import { TableDefService } from './table-def.service';

export { LsTableComponent } from './table.component';
export { ColumnConfig } from './column-config';
export { ColumnDef } from './column.def';
export { ResolveTable } from '../resource/resolve-table';
export { TableConfig } from './table-config';
export { TableConfigService } from './table-config.service';
export { TableDef } from './table.def';
export { TableDefService } from './table-def.service';

@NgModule({
    declarations: [LsTableComponent],
    exports: [LsTableComponent],
    imports: [CommonModule, FormsModule, RouterModule]
})
export class LsTableModule {
    static forRoot(): ModuleWithProviders<LsTableModule> {
        return {
            ngModule: LsTableModule, providers: [
                TableConfigService
            ]
        };
    }
}