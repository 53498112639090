import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './../auth/auth.service';
import { AuthUser } from './../auth/auth-user';
import { LsError } from './../../shared';
import { Login } from './login';

@Component({
    styleUrls: ['./login.component.css'],
    templateUrl: './login.component.html'
})
export class LoginComponent {
    isLoading: boolean = false;
    isLoaded: boolean = false;
    message: string;
    loginToken: Login = new Login();
    error: LsError;

    constructor(public authService: AuthService, public router: Router) {
        this.loginToken.isPersistent = true;
    }

    login() {
        this.isLoading = true;
        this.setMessage('Jungiamasi ...');
        this.authService.login(this.loginToken).subscribe(() => {
            this.setMessage('');

            if (this.authService.isLoggedIn) {
                // Get the redirect URL from our auth service
                // If no redirect has been set, use the default
                let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
                // Redirect the user
                this.router.navigate([redirect]);
            }
        }, (error: LsError) => {
            this.error = error;
            this.isLoading = false;
        });
    }


    private setMessage(message: string) {
        this.message = message;
    }
}
