import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlDef } from './control.def';

@Component({
    selector: 'ls-control',
    templateUrl: './control.component.html'
})
export class LsControlComponent {
    @Input() form: FormGroup;
    @Input() controlDef: ControlDef;

    get isInvalid(): boolean {
        return this.form.controls[this.controlDef.id].invalid;
    }

    get isValid(): boolean {
        return this.form.controls[this.controlDef.id].valid;
    }

    get isTouched(): boolean {
        return this.form.controls[this.controlDef.id].touched;
    }
}