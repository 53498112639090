import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

/* App Root */
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './shell/auth/error-interceptor';
import { AuthComponent } from './shell/auth/auth.component';
import { DashboardComponent } from './shell/main/dashboard.component';
import { DatabaseService, TableConfigService, I18n, LsDatepickerI18n, LsDateParserFormatter, CurrentDate, CurrentDateService } from './shared';
import { DatabaseListComponent } from './shell/database/database-list.component';
import { LoginComponent } from './shell/login/login.component';
import { LogoffComponent } from './shell/logoff/logoff.component';
import { RegisterComponent } from './shell/register/register.component';
import { UpdateDBComponent } from './shell/update-db/update-db.component';
import { HomeComponent } from './shell/main/home.component';
import { ShellComponent } from './shell/main/shell.component';
import { ShellResolve } from './shell/main/shell-resolve.service';

/* Feature Modules */
import { SharedModule, ResolveFilter, ResolveForm, ResolvePage, ResolveSingle, ResolveTable, ResolveTabView, ResourceService, TableDefService } from './shared';
import { routing, appRoutingProviders } from './app.routing';

import localeLt from '@angular/common/locales/lt';
import { registerLocaleData } from '@angular/common';
// import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryService } from './in-memory.service';

registerLocaleData(localeLt, 'lt');

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        DashboardComponent,
        DatabaseListComponent,
        HomeComponent,
        LoginComponent,
        LogoffComponent,
        RegisterComponent,
        UpdateDBComponent,
        ShellComponent
    ],
    imports: [
        HttpClientModule,
        NgbModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        routing,
        SharedModule
    ],
    providers: [
        appRoutingProviders,
        DatabaseService,
        ResolveFilter,
        ResolveForm,
        ResolvePage,
        ResolveSingle,
        ResourceService,
        ResolveTable,
        ResolveTabView,
        ShellResolve,
        TableConfigService,
        TableDefService,
        I18n,
        { provide: NgbDatepickerI18n, useClass: LsDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: LsDateParserFormatter },
        { provide: CurrentDate, useClass: CurrentDateService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: "lt" }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
