import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
//import { Http } from '@angular/http';
//import * as FileSaver from 'file-saver';

import { ControlDef } from './control.def';

@Component({
    selector: 'ls-link',
    template: '<a [attr.href]="link">Atsisiųsti</a>'
    //template: '<a (click)="downloadFile()">Atsisiųsti</a>'
})
export class LsLinkComponent implements OnInit {
    @Input() controlDef: ControlDef;
    @Input() form: FormGroup;
    link: string;

    //constructor(private http: Http) {
    //}

    ngOnInit(): void {
        if (this.form && this.controlDef) {
            if (this.form.controls[this.controlDef.id]) {
                let value = this.form.controls[this.controlDef.id].value;
                if (value) {
                    this.link = value;
                }
            }
        }
    }

    //downloadFile() {
    //    let blob = new Blob([this.http.get(this.link).map(data => { return data; })], {
    //        type: "application/xml"
    //    });
    //    FileSaver.saveAs(blob, "test.xml");

    //}
}