import { Component, OnInit } from '@angular/core';

import { TableDef } from '../table/table.module';
import { ControlDef } from '../form/control.def';

@Component({
    template: '<ls-list [tableDef]="tableDef"></ls-list>',
    selector: 'ls-dynamic-list'
})
export class LsDynamicListComponent implements OnInit {
    tableDef: TableDef;

    constructor() { }

    ngOnInit(): void {
    }
}