import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { FormDef } from '../form/form.module';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class ResolveForm implements Resolve<FormDef> {
    formDefs: { [resource: string]: Observable<FormDef>; } = {};

    constructor(private http: HttpClient) { }

    resolve(route: ActivatedRouteSnapshot): Observable<FormDef> {
        let resource: string;

        if (route.url.length === 0) {
            resource = route.parent.url[0].path;
        } else {
            resource = route.url[0].path;

            if (route.parent.url.length !== 0) {
                let parentResource = route.parent.url[0].path;

                let parentID = route.parent.params['id'];

                resource = `${parentResource}-${resource}`;
            }
        }

        //Caching
        if (!this.formDefs[resource]) {
            this.formDefs[resource] = this.http
                .get<FormDef>(`assets/def/${resource}.form.json`)
                .pipe(
                    publishReplay(1),
                    refCount()
                );
        }

        return this.formDefs[resource];
    }
}