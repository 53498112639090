import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators'

import { TableConfig } from './table-config';
import { DatabaseService } from '../database/database.service';
import { of, throwError, Observable } from 'rxjs';
import { LsError } from '../error/error';

@Injectable()
export class TableConfigService {
    cache: { [key: string]: TableConfig } = {};

    constructor(private http: HttpClient, private dbService: DatabaseService) {
    }

    get(tableName: string): Observable<TableConfig> {
        let config = this.cache[tableName];
        if (config) {
            return of(config);
        }

        const db = this.dbService.db;
        const url = `/api/${db}/tableconfig/${tableName}`;

        return this.http.get<TableConfig>(url)
            .pipe(
                map(response => {
                    let cfg = response as TableConfig;
                    this.cache[tableName] = cfg;
                    return cfg;
                },
                    catchError(this.handleError)
                )
            );
    }

    private handleError(response: HttpErrorResponse) {
        let error = new LsError(response);

        return throwError(error);
    }
}