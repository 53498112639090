import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

import { LsError } from '../../shared';
import { Login } from '../login/login';
import { AuthUser } from './auth-user';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class AuthService {
    authUser: AuthUser;

    // store the URL so we can redirect after logging in
    redirectUrl: string;
    private loginUrl = '/api/login';
    private logoffUrl = '/api/logoff';
    private authUserUrl = '/api/auth-user';

    constructor(private http: HttpClient) { }

    getAuthUser(): any {

        return this.http.get(this.authUserUrl)
            .pipe(
                tap((response: AuthUser) => {
                    if (response.name) {
                        let user = response.name;
                        this.authUser = response;
                        return user;
                    };

                    return null;
                }),
                catchError(this.handleError
                ));
    }

    isLoggedIn(): boolean {
        if (this.authUser) {
            return true;
        } else {
            //Check on server
            this.getAuthUser();

            if (this.authUser) {
                return true;
            }

            return false;
        }
    }

    login(login: Login) {
        let body = JSON.stringify(login);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };


        return this.http.post(this.loginUrl, body, httpOptions)
            .pipe(
                tap(val => {
                    this.authUser = new AuthUser();
                    this.authUser.name = login.email;
                }),
                catchError(this.handleError)
            );
    }

    logoff() {
        let body = JSON.stringify({});

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http
            .post(this.logoffUrl, body, httpOptions)
            .pipe(
                tap(val => {
                    this.authUser = null;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(response: HttpErrorResponse) {
        let error = new LsError(response);
        return throwError(error);
    }
}
