import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDatepickerI18nDefault, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { I18n } from '../../i18n/i18n';

const I18N_VALUES = {
    en: {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    lt: {
        weekdays: ['P', 'A', 'T', 'K', 'Pn', 'Š', 'S'],
        months: ['Sau', 'Vas', 'Kov', 'Bal', 'Geg', 'Bir', 'Lie', 'Rgp', 'Rgs', 'Spa', 'Lap', 'Gru'],
    }
};

@Injectable()
export class LsDatepickerI18n extends NgbDatepickerI18nDefault {
    getDayAriaLabel(date: NgbDateStruct): string {
        let labelValue = date.year * 10000 + date.month * 100 + date.day;

        return labelValue.toString();
    }

    constructor(private _i18n: I18n) {
        super('lt');
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }
    getMonthFullName(month: number): string {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }
}