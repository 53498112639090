import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LsError } from '../error/error';
import { LsFormComponent } from '../form/form.component';
import { FormDef } from '../form/form.def';
import { ResourceService } from '../resource/resource.service';


@Component({
    selector: 'ls-filter',
    templateUrl: './filter.component.html'
})
export class LsFilterComponent implements OnInit {
    @ViewChild(LsFormComponent, { static: false })
    private formComponent: LsFormComponent;

    error: LsError;
    @Input() formDef: FormDef;
    item: any;
    isDownloading: boolean;
    isSaving: boolean;
    isSubmitting: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private service: ResourceService) { }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { item: any, formDef: FormDef }) => {
                this.item = data.item;
                this.formDef = {
                    title: "Filtro laukai",
                    groups: [
                        {
                            "title": "Filtruojami laukai",
                            "continuous": true,
                            "orientation": "horizontal",
                            "id": "values",
                            "columns": [
                                {
                                    "controls": [
                                        {
                                            "controlType": "hidden",
                                            "id": "id",
                                            "label": "ID"
                                        },
                                        {
                                            "controlType": "checkbox",
                                            "id": "isSelected",
                                            "label": ""
                                        },
                                        {
                                            "controlType": "select",
                                            "id": "field",
                                            "label": "Laukas",
                                            "required": true,
                                            "select": {
                                                "resource": "filterfield",
                                                "inputFormat": ["name"],
                                                "resultFormat": ["name"],
                                                "params": [
                                                    //{
                                                    //    "key": "type",
                                                    //    "value": "type.id"
                                                    //},
                                                    //{
                                                    //    "key": "dayCount",
                                                    //    "value": "dayCount"
                                                    //}
                                                ]
                                            }
                                        },
                                        {
                                            "controlType": "text",
                                            "id": "text",
                                            "label": "Reikšmė",
                                            "required": true
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "title": "",
                            "columns": [
                                {
                                    "controls": [{
                                        "controlType": "hidden",
                                        "id": "query"
                                    }]
                                }]
                        }
                    ]
                };
                this.formComponent.update(this.item);
            });
    }

    cancel(model: any): void {
        if (this.route.snapshot.url.length === 0) {
            //Forma tabe
            this.router.navigate(['../../../'], { relativeTo: this.route });
        } else {
            //Forma iš sąrašo (kuris tiek tabe, tiek ne gali būti)
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    save(model: any): void {
        this.error = null;
        this.isSaving = true;

        let resource = this.getResource();

        this.service.save(resource, model).subscribe((o: any) => {
            //if (this.item.id === 0) {
            //    if (this.route.snapshot.url.length === 0) {
            //        this.router.navigate(['../../../' + o.query], { relativeTo: this.route });
            //    } else {
            //        this.router.navigate(['../' + o.query], { relativeTo: this.route });
            //    }

            //    this.isSaving = false;
            //} else {
            this.service.get(resource, o.query).subscribe(data => {
                this.item = data;
                this.formComponent.update(data);

                this.isSaving = false;
            });
            //}
        }, (error: LsError) => {
            this.error = error;
            this.isSaving = false;
        });
    }

    submit(model: any): void {
        this.error = null;
        this.isSubmitting = true;
        let resource = this.getResource();
        model.Query = this.getQueryName();

        this.service.save(resource, model).subscribe(() => {
            this.isSubmitting = false;

            if (this.route.snapshot.url.length === 0) {
                this.router.navigate(['../../../'], { relativeTo: this.route });
            } else {
                this.router.navigate(['../'], { relativeTo: this.route });
            }
        }, (error: LsError) => {
            this.error = error;
            this.isSubmitting = false;
        });
    }

    private getResource(): string {
        return 'filter';
    }

    private getQueryName(): string {
        let resource: string;

        if (this.route.snapshot.url.length === 0) {
            resource = this.route.snapshot.parent.url[0].path;
        } else {
            resource = this.route.snapshot.url[0].path;

            if (this.route.snapshot.parent.url.length !== 0) {
                let parentResource = this.route.snapshot.parent.url[0].path;
                resource = `${parentResource}-${resource}`;
            }
        }

        return resource;
    }
}