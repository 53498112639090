import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { GroupDef } from './group.def';

@Component({
    selector: 'ls-table-view',
    templateUrl: './table-view.component.html'
})
export class LsTableViewComponent implements OnChanges {
    @Input() formGroup: FormGroup;
    @Input() groupDef: GroupDef;
    @Output() addedNew = new EventEmitter();
    @Output() removed = new EventEmitter();
    @Input() tableName: string;

    isInvalid(lineFormGroup: FormGroup, key: string): boolean {
        return lineFormGroup.controls[key].invalid;
    }

    get labels(): string[] {
        let u: string[] = [];

        this.groupDef.columns[0].controls.forEach(o => {
            if (o.controlType === 'hidden') {
                u.push('');
            } else {
                u.push(o.label);
            }
        });

        return u;
    }

    get lineControls(): any {
        let array = <FormArray>this.formGroup.controls[this.tableName];

        return array.controls;
    }

    ngOnChanges() {
    }

    onAddNew(e: any): void {
        e.preventDefault()
        this.addedNew.emit({ id: 0 });
    }

    onRemove(e: any): void {
        e.preventDefault()
        this.removed.emit({ id: 0 });
    }
}