import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LsError } from '../error/error';
import { Page } from '../page';
import { ResourceService } from '../resource/resource.service';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, catchError, switchMap } from 'rxjs/operators';
import { Param } from '../resource/param';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteDialog } from '../dialogs/delete-dialog';
import { TableDef } from '../table/table.def';
import { DatabaseService } from '../database/database.service';

@Component({
    selector: 'ls-list',
    templateUrl: './list.component.html'
})
export class LsListComponent<T> implements OnInit {
    @Input() page: Page<T>;
    @Input() tableDef: TableDef;
    isRemoving: boolean = false;
    error: LsError;
    public term: any;

    constructor(private route: ActivatedRoute,
        private router: Router, private service: ResourceService, private dbService: DatabaseService,
        private _modalService: NgbModal) { }

    ngOnInit() {
        this.route.data
            .subscribe((data: { page: Page<T>, tableDef: TableDef }) => {
                this.page = data.page;
                this.tableDef = data.tableDef;
            });
    }

    onOpened(item: any): void {
        this.router.navigate(['./', item.id], { relativeTo: this.route });
    }

    onPageChanged(pageNo: number): void {
        let resource = this.getResource();

        this.service.getPage<T>(resource, pageNo)
            .subscribe((pageModel: Page<T>) => {
                this.page = pageModel;
            });
    }

    download(): void {
        let db = this.dbService.db;
        let resource = this.getResource();
        resource = resource.replace(new RegExp('-', 'g'), '');
        let url = `api/${db}/${resource}/xlsx`;
        window.location.href = url;
    }

    refresh(): void {
        let pageNo = this.getPageNo();

        let resource = this.getResource();

        this.service.getPage<T>(resource, pageNo).subscribe((pageModel: Page<T>) => {
            this.page = pageModel;
        });
    }

    getPageNo(): number {
        let pageNo = 1;

        if (this.page.limit != 0) {
            pageNo = Math.ceil(this.page.offset / this.page.limit) + 1;
        }

        return pageNo;
    }

    remove(): void {
        this.error = null;

        this._modalService
            .open(DeleteDialog)
            .result
            .then((result) => {
                this.isRemoving = true;
                let resource = this.getResource();

                let observableBatch: any = [];

                this.page.items.forEach((item: any, index: number) => {
                    if (item.isSelected) {
                        observableBatch.push(this.service.delete(resource, item.id));
                    }
                });

                if (observableBatch.length > 0) {
                    this.isRemoving = true;

                    forkJoin(observableBatch).subscribe(() => {
                        this.refresh();
                        this.isRemoving = false;
                    }, (error: LsError) => {
                        this.error = error;
                        this.refresh();
                        this.isRemoving = false;
                    });
                } else {
                    this.isRemoving = false;
                }
            }, () => {
            });
    }

    getResource(): string {
        let resource = this.route.snapshot.url[0].path;

        let parentUrl = this.route.snapshot.parent.url[0];

        if (parentUrl) {
            let parentResource = this.route.snapshot.parent.url[0].path;

            let parentID = this.route.snapshot.parent.params['id'];

            resource = `${parentResource}/${parentID}/${resource}`;
        }

        return resource;
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term => {
                let searchDef = this.tableDef.search;

                if (!searchDef)
                    return of([]);

                let params = new Array<Param>();

                params.push({
                    key: 'term',
                    value: term
                });

                // if (this.searchDef.select.params) {
                //     this.searchDef.select.params.forEach(param => {
                //         let val = this.byString(model, param.value);

                //         params.push({
                //             key: param.key,
                //             value: val
                //         })
                //     })
                // }

                let resource = searchDef.select.resource;

                return this.service
                    .getPage(resource, 1, params)
                    .pipe(
                        catchError(() => {
                            return of([]);
                        }),
                        map((page: Page<any>) => {
                            return page.items;
                        })
                    );
            }))

    searchSelected(event: any) {
        this.onOpened(event.item);
    }

    inputFormatter: (value: any) => string = (value: any) => {
        let searchDef = this.tableDef.search;

        if (!searchDef || !searchDef.select || !searchDef.select.inputFormat) {
            return;
        }

        return this.formatter(value, searchDef.select.inputFormat)
    };

    resultFormatter: (value: any) => string = (value: any) => {
        let searchDef = this.tableDef.search;

        if (!searchDef || !searchDef.select || !searchDef.select.resultFormat) {
            return;
        }

        return this.formatter(value, searchDef.select.resultFormat)
    };

    formatter(value: any, format: string[]) {
        let formatted = format.reduce((accumulator: string, currentValue: string, currentIndex: number) => {
            if (currentIndex != 0) {
                accumulator += ' ';
            }
            return accumulator += value[currentValue];
        }, '');

        return formatted;
    };
}
