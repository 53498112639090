import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { LsFileUploadComponent } from './file-upload.component';
import { LsAutofocusModule } from '../autofocus/autofocus.module';
import { LsCheckComponent } from './check.component';
import { LsControlComponent } from './control.component';
import { LsDateComponent } from './date.component';
import { LsErrorModule } from '../error/error.module';
import { LsFormComponent } from './form.component';
import { LsLinkComponent } from './link.component';
import { LsSelectComponent } from './select.component';
import { LsTableViewComponent } from './table-view.component';
import { LsTextComponent } from './text.component';

export { LsFileUploadComponent } from './file-upload.component';
export { FormDef } from './form.def';
export { LsCheckComponent } from './check.component';
export { LsControlComponent } from './control.component';
export { LsDateComponent } from './date.component';
export { LsFormComponent } from './form.component';
export { LsLinkComponent } from './link.component';
export { LsSelectComponent } from './select.component';
export { LsTableViewComponent } from './table-view.component';
export { LsTextComponent } from './text.component';

@NgModule({
    declarations: [
        LsFileUploadComponent,
        LsCheckComponent,
        LsDateComponent,
        LsFormComponent,
        LsLinkComponent,
        LsControlComponent,
        LsSelectComponent,
        LsTableViewComponent,
        LsTextComponent],
    exports: [LsDateComponent,
        LsFormComponent,
        LsControlComponent,
        LsTableViewComponent],
    imports: [
        CommonModule,
        LsAutofocusModule,
        LsErrorModule,
        NgbDatepickerModule,
        NgbTypeaheadModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class LsFormModule {
    static forRoot(): ModuleWithProviders<LsFormModule> { return { ngModule: LsFormModule, providers: [] }; }
}