import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { TabViewDef } from './tab-view.def';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class ResolveTabView implements Resolve<TabViewDef> {
    tabViewDefs: { [resource: string]: Observable<TabViewDef>; } = {};

    constructor(private http: HttpClient) { }

    resolve(route: ActivatedRouteSnapshot): Observable<TabViewDef> {
        let resource: string;

        if (route.url.length === 0) {
            resource = route.parent.url[0].path;
        } else {
            resource = route.url[0].path;

            if (route.parent.url.length !== 0) {
                let parentResource = route.parent.url[0].path;

                let parentID = route.parent.params['id'];

                resource = `${parentResource}-${resource}`;
            }
        }

        //Caching
        if (!this.tabViewDefs[resource]) {
            this.tabViewDefs[resource] = this.http
                .get<TabViewDef>(`assets/def/${resource}.tab-view.json`)
                .pipe(
                    publishReplay(1),
                    refCount()
                );
        }

        return this.tabViewDefs[resource];
    }
}