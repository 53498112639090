import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';

import { Column } from './column';
import { ColumnConfig } from './column-config';
import { ColumnDef } from './column.def';
import { TableConfig } from './table-config';
import { TableDef } from './table.def';

@Component({
    selector: 'ls-table',
    styleUrls: ['./table.component.css'],
    templateUrl: './table.component.html'
})
export class LsTableComponent implements OnChanges {
    @Input() rows: any[];
    @Input() config: TableConfig;
    @Input() def: TableDef;
    columns: Column[];
    @Output() open = new EventEmitter<any>();
    rowsChecked: boolean;

    constructor() {
    }

    ngOnChanges(): void {
        this.createColumns(this.def, this.config);
    }

    private createColumns(def: TableDef, config: TableConfig) {
        this.columns = new Array<Column>();

        if (def === undefined) {
            return;
        }

        if (config === undefined) {
            def.columns.forEach(d => {
                let column = new Column(d);
                column.key = d.id;
                this.columns.push(column);
            });
        }
        else {
            const configColumns = config.columns.sort((a: ColumnConfig, b: ColumnConfig) => a.order - b.order);
            configColumns.forEach(c => {
                let d = def.columns.filter((o: ColumnDef) => o.id === c.key);
                let column = new Column(d[0], c);
                this.columns.push(column);
            });
        }
    }

    check(event: any): void {
        event.stopPropagation();
    }

    openItem(row: any): void {
        this.open.emit(row);
    }

    get checkRows(): boolean {
        return this.rowsChecked;
    }

    set checkRows(rowsChecked: boolean) {
        this.rows.forEach(o => {
            o.isSelected = rowsChecked;
        });

        this.rowsChecked = rowsChecked;
    }
}