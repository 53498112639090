import { Route } from '@angular/router';
import { LsDynamicListComponent } from '../list/list.module';
import { ResolvePage } from '../resource/resolve-page';
import { ResolveTable } from '../resource/resolve-table';
import { LsFilterComponent } from '../filter/filter.component';
import { ResolveFilter } from '../resource/resolve-filter';
import { LsDynamicFormComponent } from '../edit-form/dynamic-form.component';
import { ResolveSingle } from '../resource/resolve-single';
import { ResolveForm } from '../resource/resolve-form';

export class LsTabViewRoute {
    constructor(public route: Route) { }

    addList(resource: string): LsTabViewRoute {
        this.route.children.push({
            path: resource,
            //  canActivate: [AuthGuard],
            component: LsDynamicListComponent,
            resolve: {
                page: ResolvePage,
                tableDef: ResolveTable
            }
        });

        this.route.children.push({
            path: resource + '/filter',
            //  canActivate: [AuthGuard],
            component: LsFilterComponent,
            resolve: {
                item: ResolveFilter
            }
        });

        return this;
    }

    addForm(resource: string): LsTabViewRoute {
        this.route.children.push({
            path: resource + '/:id',
            //    canActivate: [AuthGuard],
            component: LsDynamicFormComponent,
            resolve: {
                item: ResolveSingle,
                formDef: ResolveForm
            }
        });

        return this;
    }

    addSingleForm(resource: string): LsTabViewRoute {
        this.route.children.push({
            path: resource,
            //   canActivate: [AuthGuard],
            component: LsDynamicFormComponent,
            resolve: {
                item: ResolveSingle,
                formDef: ResolveForm
            }
        });

        return this;
    }
}

