import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { LsTabViewComponent } from './tab-view.component';
import { ResolveTabView } from './resolve-tab-view';

export { LsTabViewComponent } from './tab-view.component';
export { TabViewDef } from './tab-view.def';
export { ResolveTabView } from './resolve-tab-view';

@NgModule({
    declarations: [
        LsTabViewComponent],
    exports: [LsTabViewComponent],
    imports: [
        CommonModule,
        NgbNavModule,
        RouterModule
    ]
})
export class LsTabViewModule {
    static forRoot(): ModuleWithProviders<LsTabViewModule> { return { ngModule: LsTabViewModule, providers: [] }; }
}