import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlDef } from './control.def';
import { DateStruct } from './date-struct';

@Component({
    selector: 'ls-date',
    templateUrl: './date.component.html'
})
export class LsDateComponent implements OnInit {
    @Input() controlDef: ControlDef;
    @Input() form: FormGroup;
    private _model: DateStruct;

    ngOnInit(): void {
        if (this.form && this.controlDef) {
            if (this.form.controls[this.controlDef.id]) {
                let value = this.form.controls[this.controlDef.id].value;
                if (value) {
                    this._model = new DateStruct();
                    this._model.year = parseInt(value.substr(0, 4));
                    this._model.month = parseInt(value.substr(5, 2));
                    this._model.day = parseInt(value.substr(8, 2));
                }
            }
        }
    }

    get model(): DateStruct {
        return this._model;
    }

    set model(model: DateStruct) {
        if (model) {
            let date = `${model.year}-${this.padNumber(model.month)}-${this.padNumber(model.day)}T00:00:00`;
            this.form.controls[this.controlDef.id].setValue(date);
            this._model = model;
        } else {
            this.form.controls[this.controlDef.id].setValue(null);
        }
    }

    private padNumber(value: number): string {
        return `0${value}`.slice(-2);
    }
}