import { HttpErrorResponse } from '@angular/common/http';

export class LsError {
    statusText: string;
    messages: any[];

    constructor(private response: HttpErrorResponse = null) { //private response: Response = null) {
        this.messages = ['Serverio klaida.'];
        if (response !== null) {
            this.statusText = response.statusText;
            switch (response.status) {
                case 404:
                    break;
                case 500:
                    let text = response.error;

                    if (text) {
                        this.messages = [];
                        this.messages.push({ key: '', val: [text] });
                    }
                    break;
                default:
                    this.messages = this.transform(response.error);
            }
        }
    }

    private transform(dict: any): any[] {
        let a: any[] = [];
        for (let key in dict) {
            if (dict.hasOwnProperty(key)) {
                a.push({ key: key, val: dict[key] });
            }
        }

        return a;
    }
}