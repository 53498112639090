import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UpdateDBService } from './update-db.service';
import { LsError } from '../../shared';
import { UpdateDB } from './update-db';

@Component({
    styleUrls: ['./update-db.component.css'],
    templateUrl: './update-db.component.html'
})
export class UpdateDBComponent {
    isCreatingUpdating: boolean = false;
    isLoaded: boolean = false;
    message: string;
    loginToken: UpdateDB = new UpdateDB();
    error: LsError;

    constructor(private service: UpdateDBService, public router: Router) {
    }

    createUpdate() {
        this.isCreatingUpdating = true;

        this.service.createUpdate(this.loginToken).subscribe(() => {
            this.router.navigate(['/']);
        }, (error: LsError) => {
            this.error = error;
            this.isCreatingUpdating = false;
        });
    }
}
