import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { TableDefService } from '../table/table-def.service';
import { TableDef } from '../table/table.def';
import { Observable } from 'rxjs';

@Injectable()
export class ResolveTable implements Resolve<TableDef> {

    constructor(private service: TableDefService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<TableDef> {
        let resource = route.url[0].path;

        let parentUrl = route.parent.url[0];

        if (parentUrl) {
            let parentResource = route.parent.url[0].path;

            let parentID = route.parent.params['id'];

            resource = `${parentResource}-${resource}`;
        }

        return this.service.get(resource);
    }
}