import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { LsError } from '../../shared';

@Component({
    templateUrl: './logoff.component.html'
})
export class LogoffComponent implements OnInit {
    error: LsError;

    constructor(public authService: AuthService, public router: Router) {
    }

    ngOnInit() {
        this.authService.logoff()
            .subscribe(() => {
                this.router.navigate(['']);
            }, (error: LsError) => {
                this.error = error;
            });
    }
}