import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormDef } from '../form/form.def';
import { LsError } from '../error/error';
import { ResourceService } from '../resource/resource.service';
import { LsFormComponent } from '../form/form.component';
import { DatabaseService } from '../database/database.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'ls-report-form',
    templateUrl: './report-form.component.html'
})
export class LsReportFormComponent implements OnInit {
    @ViewChild(LsFormComponent, { static: false })
    private formComponent: LsFormComponent;

    error: LsError;
    @Input() formDef: FormDef;
    item: any;
    isDownloading: boolean;
    isSaving: boolean;
    isSubmitting: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private service: ResourceService,
        private http: HttpClient, private dbService: DatabaseService) { }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { item: any, formDef: FormDef }) => {
                this.item = data.item;
                this.formDef = data.formDef;

                this.formComponent.update(this.item);
            });
    }

    cancel(model: any): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    download(model: any): void {
        this.error = null;
        this.isDownloading = true;

        let resource = this.getResource();

        this.service.save(resource, model).subscribe((o: any) => {

            let resouce = this.getResource();

            let re = /\-/gi;
            let url = `api/${this.dbService.db}/${resource.replace(re, '')}report/`;

            this.openLink(url);

            this.isDownloading = false;

        }, (error: LsError) => {
            this.error = error;
            this.isDownloading = false;
        });
    }

    private openLink(url: any) {
        //if (url.match(/pdf/))
        //    window.open(url, '_blank');
        //else
        //    window.location.href = url;
        window.location.href = url;
    }

    save(model: any): void {
        this.error = null;
        this.isSaving = true;

        let resource = this.getResource();

        this.service.save(resource, model).subscribe((o: any) => {
            if (this.item.id === 0) {
                this.router.navigate(['../' + o.id], { relativeTo: this.route });

                this.isSaving = false;
            } else {
                this.service.get(this.getResource(), o.id).subscribe(data => {
                    this.item = data;
                    this.formComponent.update(data);

                    this.isSaving = false;
                });
            }
        }, (error: LsError) => {
            this.error = error;
            this.isSaving = false;
        });
    }

    submit(model: any): void {
        this.error = null;
        this.isSubmitting = true;
        let resource = this.getResource();

        this.service.save(resource, model).subscribe(() => {
            this.isSubmitting = false;
        }, (error: LsError) => {
            this.error = error;
            this.isSubmitting = false;
        });
    }

    private getResource(): string {
        return this.route.snapshot.url[0].path;
    }
}