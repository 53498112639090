import { NgModule, ModuleWithProviders } from '@angular/core';
import { Autofocus } from './autofocus';

export { Autofocus } from './autofocus';

const LS_AUTOFOCUS_DIRECTIVES = [Autofocus];

@NgModule({ declarations: LS_AUTOFOCUS_DIRECTIVES, exports: LS_AUTOFOCUS_DIRECTIVES })
export class LsAutofocusModule {
    static forRoot(): ModuleWithProviders<LsAutofocusModule> { return { ngModule: LsAutofocusModule, providers: [] }; }
}