import { Component, Input, OnChanges } from '@angular/core';
import { LsError } from './error';

@Component({
    selector: 'ls-error',
    styleUrls: ['./error.component.css'],
    templateUrl: './error.component.html'
})
export class ErrorComponent implements OnChanges {
    @Input() error: LsError;

    ngOnChanges(changes: any) {
    }
}