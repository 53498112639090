import { Directive, ElementRef, Renderer2 } from '@angular/core';

// Simple example directive that fixes autofocus problem with multiple views
@Directive({
    selector: '[autofocus]' // using [ ] means selecting attributes
})
export class Autofocus {
    constructor(el: ElementRef, renderer: Renderer2) {
        // autofocus fix for multiple views
        setTimeout(() => {
            // let type = el.nativeElement.getAttribute('type');

            // renderer.invokeElementMethod(el.nativeElement, 'focus');

            // if (type === 'text') {
            //     renderer.invokeElementMethod(el.nativeElement, 'setSelectionRange', [0, 999]);
            // }
        }, 0);
    }
}