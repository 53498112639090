import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shell/auth/auth-guard.service';
import { authRoutes, authProviders } from './shell/auth/auth.routing';

import { DatabaseListComponent } from './shell/database/database-list.component';
import { HomeComponent } from './shell/main/home.component';
import { ShellComponent } from './shell/main/shell.component';
import { ShellResolve } from './shell/main/shell-resolve.service';

const appRoutes: Routes = [
    ...authRoutes,
    {
        path: '',
        redirectTo: '/imaps',
        pathMatch: 'full'
    },
    {
        path: ':db',
        canActivate: [AuthGuard],
        component: ShellComponent,
        resolve: {
            db: ShellResolve
        },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: HomeComponent
            },
            {
                path: 'home',
                canActivate: [AuthGuard],
                component: HomeComponent
            },
            {
                path: 'pm',
                canActivate: [AuthGuard],
                loadChildren: () => import('./+hrm').then(m => m.PmModule)
            }
        ]
    }
];

export const appRoutingProviders: any[] = [
    authProviders
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, { useHash: false, relativeLinkResolution: 'legacy' });
