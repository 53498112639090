import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ResourceService } from './resource.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ResolveSingle<T> implements Resolve<T> {

    constructor(private service: ResourceService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<T> {
        let resource: string;

        if (route.url.length === 0) {
            resource = route.parent.url[0].path;
        } else {
            resource = route.url[0].path;

            if (route.parent.url.length !== 0) {
                let parentResource = route.parent.url[0].path;

                let parentID = route.parent.params['id'];

                resource = `${parentResource}/${parentID}/${resource}`;
            }
        }

        let id = route.params['id'];

        return this.service
            .get<T>(resource, id)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any) {
        return throwError(() => error);
    }
}