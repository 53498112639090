import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { refCount, publishReplay } from 'rxjs/operators'

import { LsError } from '../error/error.module';
import { TableDef } from './table.def';
import { DatabaseService } from '../database/database.service';
import { Observable } from 'rxjs';

@Injectable()
export class TableDefService {
    tableDefs: { [resource: string]: Observable<TableDef>; } = {};

    constructor(private http: HttpClient, private dbService: DatabaseService) {
    }

    get(resource: string): Observable<TableDef> {
        if (!this.tableDefs[resource]) {

            this.tableDefs[resource] = this.http
                .get<TableDef>(`assets/def/${resource}.list.json`)
                .pipe(
                    publishReplay(1),
                    refCount()
                );
        }

        return this.tableDefs[resource];
    }
}