import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlDef } from './control.def';

@Component({
    selector: 'ls-text',
    templateUrl: './text.component.html'
})
export class LsTextComponent {
    @Input() controlDef: ControlDef;
    @Input() form: FormGroup;
}