import { Component } from '@angular/core';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ls-delete-dialog',
    templateUrl: './delete-dialog.html'
})
export class DeleteDialog {
    constructor(public modal: NgbActiveModal) {
    }

    close(result: any): void {
        this.modal.close(result);
    }

    dismiss(result: any): void {
        this.modal.dismiss(result);
    }
}