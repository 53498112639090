import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlDef } from './control.def';

@Component({
    selector: 'ls-check',
    templateUrl: './check.component.html'
})
export class LsCheckComponent implements OnInit {
    @Input() controlDef: ControlDef;
    @Input() form: FormGroup;

    ngOnInit(): void {
    }
}