import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LsReportFormComponent } from './report-form.component';
import { LsErrorModule } from '../error/error.module';
import { LsFormModule } from '../form/form.module';

export { LsReportFormComponent } from './report-form.component';

@NgModule({
    declarations: [
        LsReportFormComponent
    ],
    exports: [
        LsReportFormComponent],
    imports: [
        CommonModule,
        LsErrorModule,
        LsFormModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ]
})
export class LsReportFormModule {
    static forRoot(): ModuleWithProviders<LsReportFormModule> {
        return {
            ngModule: LsReportFormModule, providers: []
        };
    }
}