import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { LsAutofocusModule } from '../autofocus/autofocus.module';
import { LsErrorModule } from '../error/error.module';
import { LsDynamicListComponent } from './dynamic-list.component';
import { LsListComponent } from './list.component';
import { LsTableModule } from '../table/table.module';

export { LsDynamicListComponent } from './dynamic-list.component';

@NgModule({
    declarations: [
        LsListComponent,
        LsDynamicListComponent],
    exports: [
        LsListComponent,
        LsDynamicListComponent],
    imports: [
        LsAutofocusModule,
        CommonModule,
        LsErrorModule,
        FormsModule,
        RouterModule,
        LsTableModule,
        NgbPaginationModule,
        NgbTypeaheadModule
    ]
})
export class LsListModule {
    static forRoot(): ModuleWithProviders<LsListModule> {
        return {
            ngModule: LsListModule, providers: [
            ]
        };
    }
}