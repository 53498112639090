import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { FormDef } from '../form/form.module';

@Component({
    template: '<ls-edit-form [formDef]="formDef"></ls-edit-form>',
    selector: 'ls-dynamic-form'
})
export class LsDynamicFormComponent implements OnInit {
    formDef: FormDef;

    constructor(private route: ActivatedRoute) { }

    ngOnInit(): void {
    }
}