import { Injectable } from '@angular/core';

export abstract class CurrentDate {
    abstract get(): Date;
}

@Injectable()
export class CurrentDateService extends CurrentDate {
    get(): Date {
        return new Date();
    }
}
