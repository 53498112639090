import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from "@angular/common/http";

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'

import { LsError } from '../error/error.module';
import { Page } from '../page';
import { DatabaseService } from '../database/database.service';
import { Param } from './param';


@Injectable()
export class ResourceService {
    constructor(private http: HttpClient, private dbService: DatabaseService) {
    }

    delete(resource: string, id: number): Observable<Response> {
        return this.http
            .delete<Response>(this.getUrl(resource, id))
            .pipe(
                catchError(this.handleError)
            );
    }

    save(resource: string, data: any): Observable<HttpResponse<any>> {
        //let body = JSON.stringify(data);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        if (data && data.id && data.id !== 0) {
            return this.http
                .put<HttpResponse<any>>(this.getUrl(resource, data.id), data, httpOptions)
                .pipe(
                    catchError(this.handleError)
                );
        } else {
            return this.http
                .post<HttpResponse<any>>(this.getUrl(resource), data, httpOptions)
                .pipe(
                    catchError(this.handleError)
                );
        }
    }

    getPage<T>(resource: string, pageNo: number, params?: Array<Param>): Observable<Page<T>> {
        let urlParams = new HttpParams();

        urlParams = urlParams.append('page', pageNo.toString());

        if (params) {
            params.forEach(param => {
                urlParams = urlParams.append(param.key, param.value);
            });
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'If-Modified-Since': 'Mon, 1 Jan 1900 00:00:00 GMT',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }),
            params: urlParams
        };

        return this.http.get<Page<T>>(this.getUrl(resource), httpOptions)
            .pipe(
                catchError(this.handleError)
            );
        //.map((response) => {
        //    if (response.ok) {
        //        let body = response.json();
        //        let page = body as Page<T>;
        //        return page;
        //    } else {
        //        return this.handleError(response);
        //    }
        //});
    }

    get<T>(resource: string, id: number): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'If-Modified-Since': 'Mon, 1 Jan 1900 00:00:00 GMT',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            })
        };

        return this.http.get<T>(this.getUrl(resource, id), httpOptions)
            .pipe(
                catchError(this.handleError)
            );

        //.map((response) => {
        //    if (response.ok) {
        //    if (response.ok) {
        //        let body = response.json();
        //        let result = body as T;
        //        return result;
        //    } else {
        //        return this.handleError(response);
        //    }
        //});
    }

    private getUrl(resource: string, id?: number): string {
        resource = resource.replace(new RegExp('-', 'g'), '');

        if (id) {
            return `api/${this.dbService.db}/${resource}/${id}`;
        } else {
            return `api/${this.dbService.db}/${resource}`;
        }
    }

    private handleError(response: HttpErrorResponse) {
        let error = new LsError(response);

        return throwError(error);
    }
}