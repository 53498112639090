import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { CurrentDate } from './current-date';

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

export function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return '';
    }
}

@Injectable()
export class LsDateParserFormatter extends NgbDateParserFormatter {
    date: CurrentDate;

    constructor(currDate: CurrentDate) {
        super();
        this.date = currDate;
    }

    parse(value: string): NgbDateStruct {
        if (value) {
            let dateParts: string = value;
            let currDate = this.date.get();
            let monthDate: number;
            let dayDate: number;
            let dateString: string;
            let monthString: string;

            dateParts = value.replace(/\s/g, '');

            if (value.includes('.') || value.includes(',') || value.includes('-')) {
                dateParts = value.replace(/[-.,]/g, '');
            }

            monthDate = currDate.getMonth() + 1;

            if (monthDate < 10) {
                monthString = '0' + monthDate;
            }

            else { monthString = monthDate.toString(); }

            switch (dateParts.length) {
                case 8:
                    dateString = dateParts;
                    break;
                case 6:
                    dateString = '20' + dateParts.substr(0, 2) + dateParts.substr(2, 2) + dateParts.substr(4, 2);
                    break;
                case 4:
                    dateString = currDate.getFullYear() + dateParts.substr(0, 2) + dateParts.substr(2, 2);
                    break;
                case 3:
                    dateString = currDate.getFullYear() + '0' + dateParts.substr(0, 1) + dateParts.substr(1, 2);
                    break;
                case 2:
                    dateString = currDate.getFullYear() + monthString + dateParts.substr(0, 2);
                    break;
                case 1:
                    dateString = currDate.getFullYear() + monthString + '0' + dateParts.substr(0, 1);
                    break;
                default:
                    return null;
            }

            monthDate = toInteger(dateString.substr(4, 2));

            dayDate = toInteger(dateString.substr(6, 2));
            if (monthDate >= 1 && monthDate <= 12 && dayDate >= 1 && dayDate <= 31) {
                return { year: toInteger(dateString.substr(0, 4)), month: monthDate, day: dayDate };
            }
        }

        return null;
    }

    format(date: NgbDateStruct): string {
        let formattedDate = date ?
            `${date.year}-${isNumber(date.month) ? padNumber(date.month) : ''}-${isNumber(date.day) ? padNumber(date.day) : ''}` :
            '';

        return formattedDate;
    }
}