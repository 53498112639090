import { Routes } from '@angular/router';

import { AuthComponent } from './auth.component';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { LoginComponent } from '../login/login.component';
import { LogoffComponent } from '../logoff/logoff.component';
import { RegisterComponent } from '../register/register.component';
import { RegisterService } from '../register/register.service';
import { UpdateDBComponent } from '../update-db/update-db.component';
import { UpdateDBService } from '../update-db/update-db.service';

export const authRoutes: Routes = [
    {
        path: 'auth',
        component: AuthComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logoff',
        component: LogoffComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'db',
        component: UpdateDBComponent
    }
];
export const authProviders = [
    AuthGuard,
    AuthService,
    RegisterService,
    UpdateDBService
];
