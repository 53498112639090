import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { LsError } from '../../shared';
import { Login } from '../login/login';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class RegisterService {
    private loginUrl = '/api/register';

    constructor(private http: HttpClient) { }

    register(login: Login) {
        let body = JSON.stringify(login);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http
            .post(this.loginUrl, body, httpOptions)
            .pipe(
                tap(val => {
                    console.log('done');
                }),
                catchError(this.handleError)
            );
    }

    private handleError(response: HttpErrorResponse) {
        let error = new LsError(response);
        return throwError(error);
    }
}
