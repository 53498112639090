import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { AuthService } from './auth.service';
import { AuthUser } from './auth-user';

@Component({
    templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {
    error: any;

    constructor(public authService: AuthService, public router: Router) {
    }

    ngOnInit() {
        this.authService.getAuthUser()
            .subscribe((value: AuthUser) => {
                if (value) {
                    let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
                    this.router.navigate([redirect]);
                } else {
                    this.router.navigate(['/login']);
                }
            }, (error: any) => {
                this.error = error;
                this.router.navigate(['/login']);
            });
    }
}
