import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { DatabaseService } from '../../shared';
import { Observable } from 'rxjs';

@Injectable()
export class ShellResolve implements Resolve<string> {

    constructor(private dbService: DatabaseService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot): Observable<string> | string {
        let db = route.params['db'];
        this.dbService.db = db;
        return db;
    }
}
