import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LsFilterComponent } from './filter.component';
import { LsErrorModule } from '../error/error.module';
import { LsFormModule } from '../form/form.module';

export { LsFilterComponent } from './filter.component';

@NgModule({
    declarations: [
        LsFilterComponent
    ],
    exports: [
        LsFilterComponent
    ],
    imports: [
        CommonModule,
        LsErrorModule,
        LsFormModule,
        FormsModule,
        RouterModule
    ]
})
export class LsFilterModule {
    static forRoot(): ModuleWithProviders<LsFilterModule> {
        return {
            ngModule: LsFilterModule, providers: []
        };
    }
}