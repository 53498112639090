import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LsAutofocusModule } from '../autofocus/autofocus.module';
import { DeleteDialog } from './delete-dialog';

export { DeleteDialog } from './delete-dialog';

@NgModule({
    declarations: [DeleteDialog],
    exports: [DeleteDialog],
    imports: [CommonModule, FormsModule, LsAutofocusModule],
    entryComponents: [DeleteDialog]
})
export class LsDeleteDialogModule {
    static forRoot(): ModuleWithProviders<LsDeleteDialogModule> { return { ngModule: LsDeleteDialogModule, providers: [] }; }
}