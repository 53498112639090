import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LsDynamicFormComponent } from './dynamic-form.component';
import { LsEditFormComponent } from './edit-form.component';
import { LsErrorModule } from '../error/error.module';
import { LsFormModule } from '../form/form.module';

export { LsDynamicFormComponent } from './dynamic-form.component';
export { LsEditFormComponent } from './edit-form.component';

@NgModule({
    declarations: [
        LsDynamicFormComponent,
        LsEditFormComponent
    ],
    exports: [
        LsDynamicFormComponent,
        LsEditFormComponent],
    imports: [
        CommonModule,
        LsErrorModule,
        LsFormModule,
        FormsModule,
        RouterModule
    ]
})
export class LsEditFormModule {
    static forRoot(): ModuleWithProviders<LsEditFormModule> {
        return {
            ngModule: LsEditFormModule, providers: []
        };
    }
}