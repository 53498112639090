import { Route, UrlMatcher, Data, ResolveData, Routes, RunGuardsAndResolvers } from '@angular/router';
import { Type } from '@angular/core';
import { AuthGuard } from '../../shell/auth/auth-guard.service';
import { LsTabViewRoute } from './tab-view-route';
import { LsDynamicListComponent } from '../list/list.module';
import { ResolvePage } from '../resource/resolve-page';
import { ResolveTable } from '../resource/resolve-table';
import { LsFilterComponent } from '../filter/filter.module';
import { ResolveFilter } from '../resource/resolve-filter';
import { LsDynamicFormComponent } from '../edit-form/edit-form.module';
import { ResolveSingle } from '../resource/resolve-single';
import { ResolveForm } from '../resource/resolve-form';
import { LsReportFormComponent } from '../report-form/report-form.component';
import { LsTabViewComponent } from '../tab-view/tab-view.component';
import { ResolveTabView } from '../tab-view/resolve-tab-view';

export class LsRoute {
    constructor(public route: Route) { }

    addList(resource: string): LsRoute {
        this.route.children.push({
            path: resource,
            //    canActivate: [AuthGuard],
            component: LsDynamicListComponent,
            resolve: {
                page: ResolvePage,
                tableDef: ResolveTable
            }
        });

        this.route.children.push({
            path: resource + '/filter',
            //     canActivate: [AuthGuard],
            component: LsFilterComponent,
            resolve: {
                item: ResolveFilter
            }
        });

        return this;
    }

    addForm(resource: string): LsRoute {
        this.route.children.push({
            path: resource + '/:id',
            //   canActivate: [AuthGuard],
            component: LsDynamicFormComponent,
            resolve: {
                item: ResolveSingle,
                formDef: ResolveForm
            }
        });

        return this;
    }

    addReportForm(resource: string): void {
        this.route.children.push({
            path: resource,
            //     canActivate: [AuthGuard],
            component: LsReportFormComponent,
            resolve: {
                item: ResolveSingle,
                formDef: ResolveForm
            }
        });
    }

    addSingleForm(resource: string): void {
        this.route.children.push({
            path: resource,
            //   canActivate: [AuthGuard],
            component: LsDynamicFormComponent,
            resolve: {
                item: ResolveSingle,
                formDef: ResolveForm
            }
        });
    }

    addTabView(resource: string): LsTabViewRoute {
        let lsRoute = new LsTabViewRoute({
            path: resource + '/:id',
            //       canActivate: [AuthGuard],
            children: [
                {
                    path: '',
                    //        canActivate: [AuthGuard],
                    component: LsDynamicFormComponent,
                    resolve: {
                        item: ResolveSingle,
                        formDef: ResolveForm
                    }
                }
            ],
            component: LsTabViewComponent,
            resolve: {
                tabViewDef: ResolveTabView
            }
        });

        this.route.children.push(lsRoute.route);

        return lsRoute;
    };
}