import { Injectable } from '@angular/core';
import { HttpEvent, HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, empty, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (this.router.url !== '/login' && err.status === 401) {
                    this.router.navigate(['/login']);
                }

                if (err.status === 404) {
                    alert(err.message);

                    return empty();
                }

                if (err.status === 500) {
                    alert('500 klaida: ' + err.error);

                    return empty();
                }

                if (err.status === 502) {
                    alert('502 klaida: ' + err.error);

                    return empty();
                }

                if (err.status === 503) {
                    alert('503 klaida: ' + err.error);

                    return empty();
                }

                if (err.status === 504) {
                    alert('504 klaida: ' + err.error);

                    return empty();
                }

                return throwError(err);
            })
        );
    }
}